<template>
  <div class="pagination__wrapper">
    <v-select
      v-model="dataSelectValuePagination"
      :options="dataSelectOptionsPagePagination"
      :searchable="false"
      :clearable="false"
      class="call-select"
      @input="(data) => clickDownUpPage('select', data)">
      <template #open-indicator="{ attributes }">
        <span v-bind="attributes">
          <font-awesome-icon :icon="dataIconDown" />
        </span>
      </template>
    </v-select>
    <call-button
      :disabled="computedDisabledDownBtn"
      :icon="dataRowLeft"
      @click="clickDownUpPage('down')" />
    <call-button
      :disabled="computedDisabledUpBtn"
      :icon="dataRowRight"
      @click="clickDownUpPage('up')" />
  </div>
</template>

<script>
import CallButton from '../common/CallButton';
import { faChevronDown, faChevronLeft, faChevronRight, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import helper, { tabelCell } from '../../service/helper';
export default {
  name: 'CallPagination',
  components: { CallButton },
  props: {
    objPagination: {
      type: Object,
      default: () => ({})
    },
    pageValue: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      dataRowLeft: faChevronLeft,
      dataRowRight: faChevronRight,
      dataIconDown: faChevronDown,
      dataComponentDeselect: {
        render: createElement => createElement(FontAwesomeIcon, {
          props: {
            icon: faTimes
          }
        })
      },
      dataSelectValuePagination: 10,
      dataSelectOptionsPagePagination: [
        10,
        30,
        50,
        100
      ]
    };
  },
  computed: {
    computedDisabledDownBtn () {
      const { page = 0 } = this.objPagination;
      if (page === 0) {
        return true;
      }
      return false;
    },
    computedDisabledUpBtn () {
      const { page = 0, limit = 0, count = 0 } = this.objPagination;
      if ((page + 1) * limit >= count) {
        return true;
      }
      return false;
    }
  },
  created () {
    const tabelCellLen = this.pageValue ? this.pageValue : tabelCell();

    if (!this.dataSelectOptionsPagePagination.some(item => item === tabelCellLen)) {
      this.dataSelectOptionsPagePagination.push(tabelCellLen);
      this.dataSelectOptionsPagePagination.sort((a, b) => a - b);
    }

    this.dataSelectValuePagination = tabelCellLen;

    const { currentRoute: { name: pageName } = {} } = this.$router;

    const _filterKey = `_filter${pageName}`;

    if (!helper.isEmpty(this.$route.query, _filterKey)) {
      try {
        const options = helper.clearObject(JSON.parse(this.$route.query[_filterKey]));

        const _arrFilter = [
          'limit',
          'page'
        ];

        for (const _key of _arrFilter) {
          if (!helper.isEmpty(options, _key)) {
            switch (_key) {
              case 'limit':
                this.dataSelectValuePagination = options[_key];
                break;
              case 'page':
                // eslint-disable-next-line vue/no-mutating-props
                this.objPagination.page = options[_key];
                break;
            }
          }
        }
      } catch (e) {
        console.log('Error query params pagination');
      }
    }
  },
  methods: {
    clickDownUpPage (value, data) {
      const { page = 0 } = this.objPagination;
      switch (value) {
        case 'down':
          if (page <= 0) {
            return;
          }
          this.$emit('on-pagination', {
            page: (page - 1),
            limit: this.dataSelectValuePagination
          });
          break;
        case 'up':
          this.$emit('on-pagination', {
            page: (page + 1),
            limit: this.dataSelectValuePagination
          });
          break;
        case 'select':
          this.$emit('on-pagination', {
            page: 0,
            limit: data
          });
          break;
      }
    }
  }
};
</script>
